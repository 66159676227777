
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isEmail, isPhone, isRequired, isZipCode } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'

import { useGetPerson, useGetPersonsBasic } from '@/api/person'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetSuppliersBasic } from '@/api/supplier'
import { useCreateApplicant, useUpdateApplicant } from '@/api/applicant'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { ApplicantOutput, ApplicantInput } from '@/api/types/applicant'

export default defineComponent({
  name: 'AddEditApplicantDialog',
  components: {
    CommonAddEditDialog,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    applicantToEdit: {
      type: Object as PropType<ApplicantOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.applicantToEdit))

    const { getPerson, data: person } = useGetPerson()

    const { exec: getPersonsBasic, data: personsBasic, isLoading: isLoadingPersonsBasic } = useGetPersonsBasic()
    getPersonsBasic()

    const { exec: getSuppliersBasic, data: suppliers, isLoading: isLoadingSuppliersBasic } = useGetSuppliersBasic()
    getSuppliersBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()
    getOrgUnitsBasic()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'person',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => personsBasic.value),
        isLoading: computed(() => isLoadingPersonsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'firstName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applicants.form.firstName') as string)],
      },
      {
        value: 'lastName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applicants.form.lastName') as string)],
      },
      {
        value: 'birthdate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'firstContacted',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'phonePrivate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phonePrivate') as string)],
      },
      {
        value: 'phoneMobile',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phoneMobile') as string)],
      },
      {
        value: 'phoneCorporate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isPhone(value, root.$t('persons.form.phoneCorporate') as string)],
      },
      {
        value: 'emailPrivate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('acquisition.applicants.form.emailPrivate') as string)],
      },
      {
        value: 'emailBusiness',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('acquisition.applicants.form.emailBusiness') as string)],
      },
      {
        value: 'emailCorporate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isEmail(value, root.$t('acquisition.applicants.form.emailCorporate') as string)],
      },
      {
        value: 'address1',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address2',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressZipCode',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isZipCode(value)],
      },
      {
        value: 'addressCity',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressCountry',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'dateForDeletion',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'supplier',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => suppliers.value ?? []),
        isLoading: computed(() => isLoadingSuppliersBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applicants.form.supplier') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'organizationalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'qualifications',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<ApplicantInput | ApplicantOutput>(
      isEditMode.value ? cloneDeep(props.applicantToEdit) : ({} as ApplicantInput)
    )

    const { createApplicant, isLoading: isLoadingCreateApplicant } = useCreateApplicant()

    async function onAdd(): Promise<void> {
      if (!isOfType<ApplicantOutput>(form.value, 'id')) {
        try {
          let updatedForm = mapBasicEntityToIdWithForm<ApplicantInput>(form.value)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          await createApplicant(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateApplicant, isLoading: isLoadingUpdateApplicant } = useUpdateApplicant()

    async function onEdit(): Promise<void> {
      if (isOfType<ApplicantOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<ApplicantOutput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateApplicant(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditApplicant = computed(() => isLoadingCreateApplicant.value || isLoadingUpdateApplicant.value)

    watch(
      () => props.applicantToEdit,
      () => {
        if (props.applicantToEdit) {
          form.value = props.applicantToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    watch(
      () => form.value.person,
      async (newVal, oldVal) => {
        if (newVal === oldVal) return

        try {
          await getPerson(Number(newVal))
        } catch (error: unknown) {
          handleError(error)
        }

        if (person.value) {
          Object.keys(person.value).forEach((key) => {
            FORM_FIELDS.forEach((field) => {
              if (field.value === key) {
                form.value[key] = person.value?.[key]
              }
            })
          })

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        }
      }
    )

    function close(): void {
      emit('reload-applicants')

      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditApplicant,
      },
      listeners: {
        onAdd,
        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
